import { Api, ApiError, ApiRequest, ApiResponse } from "./rest_api.type";
import { http, HttpResponse } from "../http";
import { mapError } from "./rest_error.helper";

interface ResponseBody<T> {
  readonly data?: T;
  readonly error?: ApiError;
}

const createApiResp = <T>(
  resp: HttpResponse<ResponseBody<T>>
): ApiResponse<T> => {
  const body = resp.body();
  const apiResp: ApiResponse<T> = {
    data: body?.data,
    error: body?.error ? mapError(body?.error) : undefined,
    status: resp.status(),
    header: resp.header,
  };
  return apiResp;
};

const getApi = async <I, O>(
  path: string,
  config?: ApiRequest<I>
): Promise<ApiResponse<O>> => {
  const resp = await http.get<I, ResponseBody<O>>(path, config);
  return createApiResp(resp);
};

const postApi = async <I, O>(
  path: string,
  config?: ApiRequest<I>
): Promise<ApiResponse<O>> => {
  const resp = await http.post<I, ResponseBody<O>>(path, config);
  return createApiResp(resp);
};

const putApi = async <I, O>(
  path: string,
  config?: ApiRequest<I>
): Promise<ApiResponse<O>> => {
  const resp = await http.put<I, ResponseBody<O>>(path, config);
  return createApiResp(resp);
};

const deleteApi = async <I, O>(
  path: string,
  config?: ApiRequest<I>
): Promise<ApiResponse<O>> => {
  const resp = await http.delete<I, ResponseBody<O>>(path, config);
  return createApiResp(resp);
};

export const api: Api = {
  put: putApi,
  get: getApi,
  post: postApi,
  delete: deleteApi,
};
