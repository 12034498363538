import { Platform } from "../platform";

const BASE_URL_ENV_NAME = "base_url";
const LOGIN_ROUTE_ENV_NAME = "login_route";
const CLIENT_ID = "client_id";

const getApiEndpoint = (): string => {
  if (Platform.isBrowser()) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const baseUrl = (window as any)[BASE_URL_ENV_NAME];
    if (baseUrl) {
      return `${baseUrl}/api/client/default`;
    }
  }
  throw new Error(`Missing "${BASE_URL_ENV_NAME}" variable.`);
};

const getAuthClientId = (): string => {
  if (Platform.isBrowser()) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clientId = (window as any)[CLIENT_ID];
    if (clientId) {
      return clientId;
    }
  }
  throw new Error(`Missing "${CLIENT_ID}" variable.`);
};

const getLoginRouteUrl = (): string => {
  if (Platform.isBrowser()) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loginRoute = (window as any)[LOGIN_ROUTE_ENV_NAME];
    if (loginRoute) {
      return loginRoute;
    }
  }
  throw new Error(`Missing "${LOGIN_ROUTE_ENV_NAME}" variable.`);
};

export const Config = {
  getApiEndpoint,
  getLoginRouteUrl,
  getAuthClientId,
};
