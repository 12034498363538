import { ApiError } from "./rest_api.type";

export interface Errors {
  [path: string]: { code: string };
}

const flattenApiError = (
  error: ApiError,
  output: Errors,
  root?: string
): void => {
  const { path, details, code } = error;
  let errorPath = root;
  if (path) {
    errorPath = root ? `${root}.${path}` : path;
    output[errorPath] = { code };
  }
  if (details && details.length > 0) {
    for (const detail of details) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      flattenApiError(detail, output, errorPath);
    }
  }
};

export const mapError = (error: ApiError, root?: string): Errors => {
  const errors: Errors = {};
  flattenApiError(error, errors, root);
  return errors;
};

const isRestErrorResponse = (value: unknown): value is ApiError => {
  return (value as ApiError).code !== undefined;
};

export const rest_helper = {
  isRestErrorResponse,
};
