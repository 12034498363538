import { Storage } from "./storage.type";

const setItem = (key: string, value: string): void => {
  window.sessionStorage.setItem(key, value);
};

const getItem = (key: string): string | null =>
  window.sessionStorage.getItem(key);

const removeItem = (key: string): void => window.sessionStorage.removeItem(key);

const sessionStorage: Storage = {
  setItem,
  getItem,
  removeItem,
};

export default sessionStorage;
