import sessionStorage from "./storage.impl";

const tokenKey = "__token__";

const getAccessToken = (): string | null => {
  const token = sessionStorage.getItem(tokenKey);
  if (token) {
    return token;
  }
  return null;
};

const throwIfInvalid = (accessToken: string): void => {
  const base64Parts = accessToken.split(".");
  let valid = false;
  if (base64Parts.length == 3) {
    const payload = JSON.parse(atob(base64Parts[1]));
    const { sub: userId } = payload;
    if (userId) {
      valid = true;
    }
  }
  if (!valid) {
    throw new Error("invalid token");
  }
};

const setAccessToken = (accessToken: string): void => {
  throwIfInvalid(accessToken);
  sessionStorage.setItem(tokenKey, accessToken);
};

const removeToken = (): void => {
  sessionStorage.removeItem(tokenKey);
};

const tokenStorage = {
  getAccessToken,
  setAccessToken,
  removeToken,
};

export default tokenStorage;
