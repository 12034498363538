import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { user } from "../user";
import { Config } from "../config";
import { EventBus } from "../event";
import { Platform } from "../platform";
import { HttpErrorManager } from "../error";
import {
  Http,
  HttpRequest,
  HttpResponse,
  HttpResponseErrorHandler,
} from "./http.type";

const isErrorResponseStatusCode = (status: number): boolean => {
  if (status >= 400 && status <= 599) {
    return true;
  }
  return false;
};

const defaultErrorHandlers: HttpResponseErrorHandler[] = [
  {
    canHandle: (resp: HttpResponse<unknown>) =>
      Platform.isBrowser() && isErrorResponseStatusCode(resp.status()),
    handle: (resp: HttpResponse<unknown>) => {
      EventBus.dispatchEvent({
        name: "on_unhandled_request_error",
        status_code: resp.status(),
      });
      return true;
    },
  },
];

const getRequest = async <I, O>(
  path: string,
  config?: HttpRequest<I>
): Promise<HttpResponse<O>> => {
  return await processRequest(
    async () => await axios.get(resolvePath(path), configureRequest(config)),
    config
  );
};

const postRequest = async <I, O>(
  path: string,
  config?: HttpRequest<I>
): Promise<HttpResponse<O>> => {
  const uri = resolvePath(path);
  return await processRequest(
    async () => await axios.post(uri, config?.body, configureRequest(config)),
    config
  );
};

const deleteRequest = async <I, O>(
  path: string,
  config?: HttpRequest<I>
): Promise<HttpResponse<O>> => {
  return await processRequest(
    async () => await axios.delete(resolvePath(path), configureRequest(config)),
    config
  );
};

const putRequest = async <I, O>(
  path: string,
  config?: HttpRequest<I>
): Promise<HttpResponse<O>> => {
  return await processRequest(
    async () =>
      await axios.put(
        resolvePath(path),
        config?.body,
        configureRequest(config)
      ),
    config
  );
};

const processRequest = async <REQUEST, RESPONSE>(
  execute: () => Promise<AxiosResponse<RESPONSE, REQUEST>>,
  config?: HttpRequest<REQUEST>
) => {
  try {
    const response = await execute();
    return createHttpResponse<REQUEST, RESPONSE>(response);
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const { response } = error;
      const errorHttpResponse = createHttpResponse<REQUEST, RESPONSE>(
        response as AxiosResponse
      );

      let handled = false;
      if (config && config.errorHandlers && config.errorHandlers.length > 0) {
        handled = HttpErrorManager.handleHttpError(
          errorHttpResponse,
          config.errorHandlers
        );
      }
      if (!handled) {
        HttpErrorManager.handleHttpError(
          errorHttpResponse,
          defaultErrorHandlers
        );
      }
      return errorHttpResponse;
    } else {
      throw error;
    }
  }
};

const resolvePath = (path: string): string => {
  if (path.charAt(0) === "/") {
    return `${Config.getApiEndpoint()}${path}`;
  }
  return path;
};

const configureRequest = <I>(
  config?: HttpRequest<I>
): AxiosRequestConfig | undefined => {
  const request: AxiosRequestConfig = {
    withCredentials: true,
  };
  const headers: { [key: string]: string } = {};
  let authRequired = true;
  if (config) {
    const configHeaders = config.headers;
    if (configHeaders) {
      Object.keys(configHeaders).forEach(
        (key) => (headers[key] = configHeaders[key])
      );
    }
    request.timeout = config.timeout;
    if (config.queryParams) {
      request.params = config.queryParams;
    }
    if (config.authRequired !== undefined && config.authRequired !== null) {
      authRequired = config.authRequired;
    }
  }

  if (authRequired) {
    headers["Authorization"] = `Bearer ${user.getAccessToken()}`;
  }
  request.headers = headers;
  return request;
};

const createHttpResponse = <REQUEST, RESPONSE>(
  response: AxiosResponse<RESPONSE, REQUEST>
): HttpResponse<RESPONSE> => {
  const resp = {
    status: () => response && response.status,
    header: (name: string) => response && response.headers[name],
    body: () => (response && response.data) || undefined,
    isOk: () =>
      (response && response.status >= 200 && response.status <= 299) || false,
  };
  return resp;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAxiosError = (error: any): error is AxiosError => {
  return error.response !== null;
};

const http: Http = {
  get: getRequest,
  put: putRequest,
  post: postRequest,
  delete: deleteRequest,
};

export default http;
