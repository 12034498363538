import { User, UserService } from "./user.type";
import tokenStorage from "./token_storage.impl";

const createUser = (accessToken: string): User => {
  const base64Parts = accessToken.split(".");
  if (base64Parts.length == 3) {
    const payload = JSON.parse(atob(base64Parts[1]));
    const { sub: userId } = payload;
    if (userId) {
      return {
        user_id: userId,
      };
    }
  }
  throw new Error("invalid token");
};

const isSignedIn = (): boolean => {
  const token = tokenStorage.getAccessToken();
  if (token && token.length > 0) {
    return true;
  }
  return false;
};

const getUser = (): User | null => {
  const token = tokenStorage.getAccessToken();
  if (token && token.length > 0) {
    return createUser(token);
  }
  return null;
};

const isUser = (value: unknown): value is User => {
  return (value as User).user_id !== undefined;
};

const userService: UserService = {
  getAccessToken: () => tokenStorage.getAccessToken(),
  isSignedIn,
  getUser,
  isUser,
};

export default userService;
