import { Platform } from "../platform";
import { dispatchBrowserCustomEvent } from "./browser_event";
import { ApiEvent } from "./event";

const dispatchEvent = <T extends ApiEvent>(event: T): void => {
  if (Platform.isBrowser()) {
    dispatchBrowserCustomEvent(event);
  }
};

export const EventBus = {
  dispatchEvent,
};
