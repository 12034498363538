import userService from "../user/user.impl";
import tokenStorage from "../user/token_storage.impl";
import { User } from "../user";
import { ApiError } from "../rest_api";
import { endpoints } from "./endpoint";
import { http, HttpResponse } from "../http";
import { AuthRequest, AuthService, AuthResponse, Challenge } from "./auth.type";

interface TokenAuthRequest {
  readonly grant_type: string;
  readonly user_id: string;
  readonly user_secret: string;
}

interface Token {
  readonly type: string;
  readonly expires_at: number;
  readonly access_token: string;
}

interface RespBody<T> {
  readonly data?: T; // response body is wrapped inside data property
  readonly error?: ApiError;
}

const logoutKey = "logout";
const baseHeaders = {
  "Content-Type": "application/json",
};

window.onstorage = (event: StorageEvent) => {
  if (event.key === logoutKey) {
    tokenStorage.removeToken();
  }
};

const isChallenge = (value: unknown): value is Challenge => {
  return (value as Challenge).challenge !== undefined;
};

const createUser = (resp: HttpResponse<RespBody<Token>>): User | null => {
  if (resp.isOk()) {
    const accessToken = resp.body()?.data?.access_token || null;
    if (accessToken) {
      tokenStorage.setAccessToken(accessToken);
      return userService.getUser();
    }
  }
  return null;
};

const doSignIn = async (request: AuthRequest): Promise<AuthResponse> => {
  const authRequest: TokenAuthRequest = {
    grant_type: "password",
    user_secret: request.clientSecret,
    user_id: request.clientId,
  };

  const resp: HttpResponse<RespBody<Token>> = await http.post(
    endpoints.tokenEndpoint,
    {
      body: authRequest,
      headers: baseHeaders,
      authRequired: false,
    }
  );

  const user = createUser(resp);
  if (user) {
    return {
      data: user,
      redirect_after_success: resp.header("location"),
    };
  }
  throw new Error("Failed authentication");
};

const isAuthenticated = (): boolean => {
  return userService.isSignedIn();
};

const doSignOut = async (): Promise<void> => {
  const resp = await http.delete(endpoints.refreshTokenEndpoint, {
    headers: baseHeaders,
  });
  if (resp.isOk()) {
    tokenStorage.removeToken();
    window.localStorage.setItem(logoutKey, `${Date.now()}`);
    window.location.assign("/login");
  }
};

const doAutoSignIn = async (): Promise<AuthResponse> => {
  const resp: HttpResponse<RespBody<Token>> = await http.post(
    endpoints.refreshTokenEndpoint,
    {
      headers: baseHeaders,
      authRequired: false,
    }
  );
  const user = createUser(resp);
  if (user) {
    return {
      data: user,
      redirect_after_success: resp.header("location"),
    };
  }
  return {};
};

const authService: AuthService = {
  signIn: doSignIn,
  isAuthenticated,
  signOut: doSignOut,
  isNotAuthenticated: () => !isAuthenticated(),
  autoSignIn: doAutoSignIn,
  isChallenge,
};

export default authService;
