import { HttpResponse, HttpResponseErrorHandler } from "../http";

const handleHttpError = <O>(
  httpResponse: HttpResponse<O>,
  handlers: HttpResponseErrorHandler[]
): boolean => {
  for (const handler of handlers) {
    if (handler.canHandle(httpResponse)) {
      return handler.handle(httpResponse);
    }
  }
  return false;
};

export const HttpErrorManager = {
  handleHttpError,
};
