import { api } from "../rest_api";
import { Feature, FeatureRequest, FeatureResponse } from "./feature.type";

const runFeature = async <I, O>(
  name: string,
  config?: FeatureRequest<I>
): Promise<FeatureResponse<O>> => {
  const resp = await api.post<I, O>(`/features?_fid=${name}`, config);
  return {
    data: resp.data,
    status: resp.status,
    error: resp.error,
  };
};

const queryFeature = async <I, O>(
  name: string,
  config?: FeatureRequest<I>
): Promise<FeatureResponse<O>> => {
  const resp = await api.get<I, O>(`/features?_fid=${name}`, config);
  return {
    data: resp.data,
    status: resp.status,
    error: resp.error,
  };
};

const feature: Feature = {
  run: runFeature,
  query: queryFeature,
};

export default feature;
